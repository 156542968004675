export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~5],
		"/about-us": [6],
		"/best-sellers": [~7],
		"/cart": [~8,[2]],
		"/cart/checkout": [~9,[2,3]],
		"/cart/checkout/complete-purchase": [~11],
		"/cart/checkout/[slug]": [~10,[2,3]],
		"/cart/reorder": [~12,[2]],
		"/collections": [~13],
		"/collections/cosmic-cat-eye": [~15],
		"/collections/velvet-cat-eye": [~16],
		"/collections/[slug]": [~14],
		"/color-dip": [~17],
		"/contact": [18],
		"/customers/account": [~19],
		"/customers/account/order_id/[[slug]]": [~20],
		"/customers/sended-email": [21],
		"/customers/signout": [~22],
		"/deals": [~23],
		"/essentials": [~24],
		"/faq-s": [25],
		"/forget-password": [26],
		"/gel-polish": [27],
		"/gel-polish/cream-gel": [~28],
		"/gel-polish/fortify-protect": [~29],
		"/gel-polish/led-gel-polish": [~30],
		"/how-to": [~31,[4]],
		"/how-to/[slug]": [~32,[4]],
		"/kits": [~33],
		"/led-lights": [~34],
		"/privacy-policy": [35],
		"/products/[...slug]": [~36],
		"/shipping-returns": [37],
		"/signup": [38],
		"/social": [39],
		"/soft-gel": [~40],
		"/video": [41],
		"/warrantyregistration/index": [42],
		"/warrantyregistration/index/success": [43]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';